<template>
  <div
    class="flex h-full max-h-full min-h-0 flex-1 flex-col overflow-hidden rounded-md"
  >
    <template v-if="invoice">
      <PdfViewer
        ref="pdf-viewer"
        :url="`/invoicing/${$route.params.invoicing_id}/payment/${$route.params.payment_id}/render`"
        :params="printSettings"
        :title=" $t('attributes.payments')"
        :back-route="{ name: 'invoice_info_route' }"
        :info-model="{
          type: 'invoice',
          id: $route.params.invoicing_id,
          dossier_id: invoice.dossier_id,
        }"
        reverse-panel-position
      />
    </template>
    <div v-else class="flex flex-1 items-center justify-center bg-gray-700">
      <LoadingComponent />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Country } from '@/assets/enums'
import PdfViewer from '@c/Media/PdfViewer.vue'
import LoadingComponent from '@c/TheLoadingComponent.vue'

export default {
  name: 'PaymentPrintPage',
  components: {
    LoadingComponent,
    PdfViewer,
  },
  data() {
    return {
      loading: false,
      invoice: null,
      printSettings: {}
    }
  },
  computed: {
    ...mapGetters({
      default_country: 'metadata/getDefaultCountry',
      fh_settings: 'metadata/getFuneralHomeSettings',
      invoicing_categories: 'metadata/getInvoicingCategoriesMetadata'
    }),
    attachable() {
      return {
        type: 'invoice',
        id: this.$route.params.invoicing_id,
        metadata: this.printSettings
      }
    },
    defaultAttachments() {
      if (!this.invoice) return null
      return {
        name: `${this.$t(
          'enums.invoicing.docTypes.' + this.invoice?.doc_type
        )} ${this.invoice?.reference}`
      }
    }
  },
  mounted() {
    this.loading = true
    console.log(this.$route.params)
    // on fetch la facture.
    // avant de l'afficher dans le viewer, on fetch les headers pour attribuer le header par défaut.
    // quand les headers sont arrivé, on attribue la facture dans le data pour que le viewer s'affiche
    this.fetchInvoiceID({
      id: this.$route.params.invoicing_id,
      supplier_order_index: null
    }).then((response_invoice) => {
      this.fetchHeaderForInvoice({
        invoice_id: response_invoice.invoice.id
      })
        .then((response_header) => {
          this.headers = response_header.headers
          this.printSettings = {
            ...this.printSettings,
            header:
              this.headers.find((header) => header.favorite_invoice)?.id ??
              this.headers.find((header) => header.favorite)?.id ??
              null,
            normalized: this.default_country === Country.FRANCE,
            detailed: this.default_country !== Country.FRANCE
          }
          if (response_invoice.invoice.person_id) {
            this.printSettings.language =
              response_invoice.invoice?.person?.language ?? this.$i18n?.locale
          } else {
            this.printSettings.language = this.$i18n?.locale
          }

          if (
            this.fh_settings?.[response_invoice.invoice.funeral_home_id]
              ?.invoicing_printing_settings?.[response_invoice.invoice.doc_type]
          ) {
            this.printSettings = {
              ...this.printSettings,
              ...this.fh_settings[response_invoice.invoice.funeral_home_id][
                'invoicing_printing_settings'
                ][response_invoice.invoice.doc_type]
            }
          } else {
            this.printSettings.normalized =
              this.default_country === Country.FRANCE
            this.printSettings.summary = this.default_country !== Country.FRANCE
          }

          this.fixCategorySettings()

          this.invoice = response_invoice.invoice
        })
        .finally((_) => {
          this.loading = false
          console.log(this.printSettings)
        })
    })
  },
  methods: {
    ...mapActions({
      fetchInvoiceID: 'invoicing/fetchInvoiceID',
      fetchHeaderForInvoice: 'invoicing/fetchHeaderForInvoice'
    }),
    ...mapMutations({
      processSetIsEmailModalOpenMutation: 'email/setIsModalOpen',
      processAddAttachableMutations: 'email/addAttachable',
      processAddDefaultAttachmentsMutation: 'email/addDefaultAttachment'
    }),
    sendByEmail() {
      this.processAddAttachableMutations(this.attachable)
      this.processAddDefaultAttachmentsMutation(this.defaultAttachments)
      this.processSetIsEmailModalOpenMutation(true)
    },
    refreshDocument() {
      this.$refs['pdf-viewer'].reload()
    },
    isRequireSendMail() {
      return this.$route.name === 'invoice_print_route_require_send_mail'
    },
    fixCategorySettings() {
      this.invoicing_categories.map((invoicing_categories) => {
        this.printSettings.category_settings = {
          ...this.printSettings.category_settings,
          [invoicing_categories.id]: {
            mode:
              this.printSettings.category_settings?.[invoicing_categories.id]
                ?.mode ?? 'DTL'
          }
        }
      })
    }
  }
}
</script>
