<template>
  <SelectComponent
    v-model="localFrom"
    :label="$t('global.email_from')"
    name="from"
    :values="validFrom"
    display-key="label_select"
    :disabled="disabled"
    :required="required"
  />
</template>

<script>
import { defineComponent } from 'vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'SelectEmailFrom',
  components: { SelectComponent },
  props: {
    from: {
      type: Object,
      required: true,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:from'],
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
      funeral_homes: 'metadata/getFuneralHomesMetadata'
    }),
    validFrom() {
      return [
        {
          id: this.auth.id,
          name: this.auth.full_name,
          type: 'App\\Models\\User',
          emailable: this.auth.email,
          label_select: `${this.auth.full_name} - ${this.auth.email}`,
          disabled: this.auth.email.length === 0
        },
        ...this.funeral_homes.map((funeralHome) => {
          return {
            id: funeralHome.id,
            emailable: funeralHome.email,
            type: 'App\\Models\\FuneralHome',
            name: funeralHome.name,
            label_select: `${funeralHome.name} - ${funeralHome.email}`,
            disabled: this.auth.email.length === 0
          }
        })
      ]
    },
    localFrom: {
      get() {
        return this.from?.id
      },
      set(value) {
        this.$emit(
          'update:from',
          value ? this.validFrom.find((item) => item.id === value) : null
        )
      }
    }
  }
})
</script>
