<template>
  <div class="min-w-0 flex-1">
    <SettingsPageFull v-if="initialMetadata">
      <template #title>
        <div class="flex space-x-3">
          <span class="text-3xl font-bold capitalize-first">
            {{ $t('global.items_manage') }}
          </span>
        </div>
      </template>

      <template #cta>
        <BaseButton
          :title="$t('button.back')"
          color="dark"
          icon="long-arrow-left"
          @click.prevent="goBack"
        />
      </template>

      <template #body>
        <ExcelManager />
      </template>
    </SettingsPageFull>
  </div>
</template>

<script>
import BasePaginationMixin from '@u/mixins/Pagination/BasePaginationMixin'
import SettingsPageFull from '@c/SettingsPageFull.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import ExcelManager from '@c/Excel/ExcelManager.vue'

export default {
  name: 'TheItemManagePage',
  components: { ExcelManager, BaseButton, SettingsPageFull },
  props: {},
  mixins: [BasePaginationMixin],
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'settings_list_item_route' })
    }
  }
}
</script>
