import apiClient from '@u/apiClient'
import Promise from 'lodash-es/_Promise'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async fetchItemsDossier({ commit }, payload) {
    await apiClient
      .get(`dossier/fetch-items-dossier/${payload.dossier_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setDossierItems', response.data.items)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('addItemsToDossier', reason)
      })
  },
  async addItemsToDossier({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .post(`dossier/${payload.id}/item`, payload.data)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  async addPacksToDossier({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .post(`dossier/${payload.id}/pack`, payload.data)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async storeNewInvoiceForInvoiceable({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier}/invoice-for-invoiceable/${payload.invoiceable}`, payload.invoice)
        .then(async (response) => {
          resolve(response.data)
          if (response.status === 201) {
            await payload.successCallback(response?.data?.invoice?.id)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  updateItemDossier({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `/dossier/${payload.dossier_id}/item-dossier/${payload.item_dossier_id}`,
          payload.item_dossier
        )
        .then((response) => {
          // commit('setDossier', response.data.dossier)
          // commit('setDossierMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  deleteItemDossier({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(
          `/dossier/${payload.dossier_id}/item-dossier/${payload.item_dossier_id}`
        )
        .then((response) => {
          // commit('setDossier', response.data.dossier)
          // commit('setDossierMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchItemsDossierStockable({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/dossier/${payload.dossier_id}/stockable-items`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  exportItemDossierPDF: ({ commit }, payload) => {
    console.log('rigth action')
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/dossier/${payload.dossier_id}/item-dossier/${payload.item_dossier_id}/render`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
}
