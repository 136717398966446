<template>
  <div>
    <template v-if="editionMode">
      <WalkmanButton
        v-model="internalValue"
        :options="dynamicOptions"
        :label="label"
        :required="required"
        :trackBy="trackBy"
        :attributeLabel="attributeLabel"
        :size="size"
        :name="name"
        :editionMode="editionMode"
      />
      <ErrorContainer :error-key="name" :errors="errors" />
    </template>
    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="displaydValueWhenNotInEditMode"
      />
    </template>
  </div>
</template>

<script>
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import WalkmanButton from '@c/WalkmanButton.vue'

export default {
  name: 'BaseShowEditUndefinedRadioGroup',
  components: { WalkmanButton, BaseShowLabel, ErrorContainer },
  props: {
    modelValue: {
      type: [Boolean, Number, String],
      required: false,
      default: null
    },
    name: { type: String, required: true },
    label: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    errors: { type: Object, required: false, default: null },
    isVertical: { type: Boolean, required: false, default: false },
    editionMode: {
      type: Boolean,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'value'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'label'
    },
    withUndefined: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      default: '',
      required: false
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {}
  },
  computed: {
    options() {
      return [
        {
          value: 1,
          label: this.$t(`enums.yesNo.true`)
        },
        {
          value: 0,
          label: this.$t(`enums.yesNo.false`)
        },
        {
          value: null,
          label: this.$t(`enums.yesNo.undefined`)
        }
      ]
    },
    dynamicOptions() {
      return this.options.filter(
        (option) => option.label !== 'undefined' || this.withUndefined
      )
    },
    internalValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.$emit('change', value ?? null)
      }
    },
    displaydValueWhenNotInEditMode() {
      if (this.internalValue === null) {
        return this.$t(`enums.yesNo.undefined`)
      }
      return this.$t(`enums.yesNo.${Boolean(this.internalValue)}`)
    }
  }
}
</script>
