<template>
  <button
    type="button"
    @click="open = true"
    class="flex flex-row items-center justify-center gap-2 rounded-md border bg-white p-2 font-bold dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
  >
    <FontAwesomeIcon :icon="['fal', 'circle-question']" size="lg" />
    <span>Aide</span>
  </button>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" @close="open = false" class="relative z-100">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-3 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform overflow-hidden rounded-xl bg-white p-6 pt-5 text-left align-middle shadow-xl transition-all dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
            >
              <div class="flex flex-row">
                <DialogTitle
                  as="h3"
                  class="flex-1 text-xl font-bold leading-6 text-primary dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
                >
                  Vous avez besoin d'aide ?
                </DialogTitle>
                <button
                  type="button"
                  class="shrink-0 rounded-md text-gray-700 hover:text-gray-900 focus:outline-none dark:bg-slate-700"
                  @click="open = false"
                >
                  <FontAwesomeIcon :icon="['fal', 'times']" size="xl" />
                </button>
              </div>

              <div class="mt-3 flex flex-col gap-3">
                <div class="flex flex-col gap-2">
                  <div>
                    <h3 class="text-base font-bold">
                      Consultez notre documentation
                    </h3>
                    <p>
                      retrouvez nos guides détaillés pour tout comprendre de
                      Funeral Manager
                    </p>
                  </div>
                  <div class="grid grid-cols-4 gap-4 text-center">
                    <a
                      class="flex w-full flex-col items-center justify-center rounded-lg border p-2"
                      href="https://portal.funeralmanager.be/support/solutions/articles/103000329944-cr%C3%A9er-un-nouveau-dossier-client"
                      target="_blank"
                    >
                      <div>
                        <FontAwesomeIcon :icon="['far', 'books']" size="2x" />
                      </div>
                      <div class="font-semibold">Dossier</div>
                    </a>
                    <a
                      class="flex w-full flex-col items-center justify-center rounded-lg border p-2"
                      href="https://portal.funeralmanager.be/support/solutions/articles/103000329397-agenda"
                      target="_blank"
                    >
                      <div>
                        <FontAwesomeIcon
                          :icon="['far', 'calendar']"
                          size="2x"
                        />
                      </div>
                      <div class="font-semibold">Agenda</div>
                    </a>
                    <a
                      class="flex w-full flex-col items-center justify-center rounded-lg border p-2"
                      href="https://portal.funeralmanager.be/support/solutions/103000257883"
                      target="_blank"
                    >
                      <div>
                        <FontAwesomeIcon :icon="['fal', 'cogs']" size="2x" />
                      </div>
                      <div class="font-semibold">Paramètres</div>
                    </a>
                    <a
                      class="flex w-full flex-col items-center justify-center rounded-lg border bg-gray-300 p-2"
                      href="https://portal.funeralmanager.be/support/solutions"
                      target="_blank"
                    >
                      <div class="font-semibold">Tous les articles</div>
                      <div>
                        <FontAwesomeIcon
                          :icon="['fas', 'arrow-right']"
                          size="2x"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="flex flex-col gap-2">
                  <div>
                    <h3 class="text-base font-bold">Assistance et Support</h3>
                    <p>
                      Notre équipe de support est a votre disposition pour vous
                      aider.
                    </p>
                  </div>
                  <div class="grid grid-cols-2 gap-4 text-center">
                    <a
                      class="flex w-full flex-col items-center justify-center rounded-lg border bg-primary p-2 text-white"
                      href="https://portal.funeralmanager.be/"
                      target="_blank"
                    >
                      <div>
                        <FontAwesomeIcon :icon="['fal', 'ticket']" size="2x" />
                      </div>
                      <div class="font-semibold">Envoyer un ticket</div>
                    </a>
                    <a
                      class="flex w-full flex-col items-center justify-center rounded-lg border bg-primary p-2 text-white"
                      href="mailto:support@funeralmanager.be"
                      target="_blank"
                    >
                      <div>
                        <FontAwesomeIcon
                          :icon="['fal', 'envelope']"
                          size="2x"
                        />
                      </div>
                      <div class="font-semibold">Nous écrire par mail</div>
                    </a>
                  </div>
                </div>
                <div class="flex flex-col gap-2">
                  <div>
                    <h3 class="text-base font-bold">Une urgence ?*</h3>
                  </div>
                  <div class="grid grid-cols-4 gap-4 text-center">
                    <a
                      class="flex w-full flex-col items-center justify-center gap-2 rounded-lg border p-2"
                      href="callto:+3228998666"
                      target="_blank"
                    >
                      <div>
                        <img
                          :src="LanguagesFlags[Languages.FRENCH_BELGIUM]"
                          class="h-6"
                        />
                      </div>
                      <div class="font-semibold">02 899 86 66</div>
                    </a>
                    <a
                      class="flex w-full flex-col items-center justify-center gap-2 rounded-lg border p-2"
                      href="callto:+3228998666"
                      target="_blank"
                    >
                      <div>
                        <img
                          :src="LanguagesFlags[Languages.NEDERLANDS_BELGIUM]"
                          class="h-6"
                        />
                      </div>
                      <div class="font-semibold">02 899 86 66</div>
                    </a>
                    <a
                      class="flex w-full flex-col items-center justify-center gap-2 rounded-lg border p-2"
                      href="callto:+3228998666"
                      target="_blank"
                    >
                      <div>
                        <img
                          :src="LanguagesFlags[Languages.FRENCH_FRANCE]"
                          class="h-6"
                        />
                      </div>
                      <div class="font-semibold">03 53 32 00 17</div>
                    </a>
                  </div>
                  <div>
                    <p class="text-xxs italic">
                      * les appels sont réservés aux urgences. Tout appel
                      non-urgent pourrait faire l'objet d'une facturation.
                    </p>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import LanguagesFlags from '@/assets/enums/config/LanguagesFlags'
import Languages from '@/assets/enums/config/Languages'

export default {
  name: 'HelpMeModal',
  computed: {
    Languages() {
      return Languages
    },
    LanguagesFlags() {
      return LanguagesFlags
    }
  },
  data() {
    return {
      open: false
    }
  },
  components: {
    BaseButton,
    FontAwesomeIcon,
    Dialog,
    DialogTitle,
    DialogPanel,
    TransitionRoot,
    TransitionChild
  }
}
</script>
