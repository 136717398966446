/* eslint-disable */
import apiClient from '@u/apiClient'

let base_error = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  fetchEvents({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/planning/fetch-events`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  //region fetch
  async fetchEventsOfTheDay({ commit, getters }, payload) {
    await apiClient
      .post(`/planning/fetch-events-of-the-day`, payload)
      .then(async (response) => {
        // console.log('res', response)
        if (response.data) {
          await commit('setEvents', response.data.events)
          await commit('setPlanningMetadata', response.data.metadata)
        }
      })
      .catch(async (reason) => {
        console.error('fetchEvents', reason)
      })
  },
  async fetchUnplannedEvents({ commit, getters }) {
    await apiClient
      .post(`/agenda/fetch-unplanned-events`)
      .then(async (response) => {
        // console.log('res', response)
        if (response.data) {
          // await commit('setEvents', response.data.events)
          await commit('setEventsUnplanned', response.data.unPlanned)
        }
      })
      .catch(async (reason) => {
        console.error('fetchEvents', reason)
      })
  },

  async fetchEventActivityByEventID({ commit }, payload) {
    await apiClient
      .get(`planning/fetch-activities/${payload.id}`)
      .then(async (response) => {
        await commit('setEventActivity', response.data.activities)
      })
      .catch(async (reason) => {
        await console.error('fetchEventActivityByEventID', reason)
      })
  },

  //endregion
  addAssignedPerson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/planning/${payload.event_id}/person`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  addAssignedPersonToMaterialResource({ commit }, payload) {
    console.log('add', payload)
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/planning/${payload.event_id}`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  //endregion
  copyAssignedPeople({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/planning/${payload.event_id}/copy-people`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  copyAssignedMaterialResource({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/planning/${payload.event_id}/copy-material-resource`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  removeAssignedPerson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`/planning/${payload.event_id}/people/remove`, {
          data: payload.data
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  addAssignedMaterialResource({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/planning/${payload.event_id}/material-resource`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateAssignedPerson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `/planning/${payload.event_id}/person/${payload.user_id}`,
          payload.data
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  updateAssignedPeople({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`/planning/${payload.event_id}/people/`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  copyAssignedPeopleDossier({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/planning/copy-assign-people-dossier/${payload.event_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  copyMaterialResourceDossier({ commit }, payload) {
    console.log('test')
    return new Promise((resolve, reject) => {
      apiClient
        .post(
          `/planning/${payload.event_id}/copy-material-resource-dossier/${payload.material_resource_id}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  removeAssignedMaterialResource({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(
          `/planning/${payload.event_id}/material-resource/${payload.material_resource_id}`,
          {
            params: payload.params
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateAssignedMaterialResource({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `/planning/${payload.event_id}/material-resource/${payload.material_resource_id}`,
          payload.data
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  setDataTransferObject({ commit }, payload) {
    commit('setDataTransferObject', payload)
  },

  deleteEvent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`agenda/event/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  undoDeleteEvent: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`agenda/event/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
}
