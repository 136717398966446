<template>
  <h1>Page en construction</h1>
  <!--  <hot-table :settings="localSettings" :data="localData"></hot-table>-->
</template>

<script>
import { HotTable } from '@handsontable/vue3'
import { faker } from '@faker-js/faker'
import moment from 'moment'

export default {
  name: 'ExcelManager',
  components: { HotTable },
  props: {
    settings: {
      type: Object,
      required: false,
      default: () => {}
    },
    columns: {
      type: Array,
      required: false,
      default: null
    },
    licenseKey: {
      type: String,
      required: false,
      default: 'non-commercial-and-evaluation'
    }
  },
  data() {
    return {
      buildConfig: {},
      defaultSettings: {
        contextMenu: [
          //'cut',
          'copy',
          '---------',
          'row_above',
          'row_below',
          'remove_row'
          //'---------',
          //'alignment',
          //'make_read_only',
          //'clear_column'
        ],
        themeName: 'ht-theme-main',
        colHeaders: true,
        rowHeaders: true,
        height: '65vh',
        autoWrapRow: true,
        autoWrapCol: true,
        manualRowResize: true,
        manualColumnResize: true,
        navigableHeaders: true
      }
    }
  },
  computed: {
    localSettings() {
      return Object.assign({}, this.defaultSettings, this.settings, {
        licenseKey: this.licenseKey,
        columns: this.localColumns
      })
    },
    localColumns() {
      if (this.columns && this.columns.length > 0) {
        return this.columns
      }

      return [
        {
          title: 'The name',
          type: 'text',
          data: `name.${this.$i18n.locale}`
        },
        {
          title: 'First name',
          type: 'text',
          data: 'firstName'
        },
        {
          title: 'Last name',
          type: 'text',
          data: 'lastName'
        },
        {
          title: 'Birthday',
          type: 'date',
          data: 'birthday',
          dateFormat: 'DD/MM/YYYY',
          correctFormat: true
        },
        {
          title: 'Age',
          data: 'age',
          type: 'numeric'
        },
        {
          title: 'Salary',
          data: 'salary',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'fr-FR' // this is the default culture, set up for USD
          }
        },
        {
          title: 'Civility',
          data: 'civility',
          type: 'dropdown',
          source: ['mister', 'woman', 'boy', 'girl']
        },
        {
          title: 'Enable',
          data: 'enable',
          type: 'checkbox'
        }
      ]
    },
    localData() {
      if (this.data && this.data.length > 0) {
        return this.data
      }

      let defaultData = []

      for (let i = 0; i < 90; i++) {
        let sex = faker.person.sex()
        let birthday = faker.date.birthdate()

        defaultData.push({
          firstName: faker.person.firstName(sex),
          lastName: faker.person.lastName(sex),
          birthday: moment(birthday).format('DD/MM/YYYY'),
          age: 38,
          salary: faker.number.float({
            min: 235.864554654,
            max: 654132.456415
          }),
          civility: faker.helpers.arrayElement([
            'mister',
            'woman',
            'boy',
            'girl'
          ]),
          enable: faker.helpers.arrayElement([true, false]),
          name: {
            fr_BE: 'Merci',
            nl_BE: 'Bedankt'
          }
        })
      }

      return defaultData
    }
  }
}
</script>
