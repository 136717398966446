<template>
  <div class="flex flex-col gap-4 p-4">
    <PageSection :title="$t('global.informations')">
      <div class="grid grid-cols-1 gap-x-5 gap-y-2 md:grid-cols-2">
        <BaseShowEditTranslation
          v-if="payment_mode.denomination"
          v-model:translations="payment_mode.denomination"
          edition-mode
          :label="$t('attributes.denomination')"
          :required="true"
          :errors="errors?.denomination?.[$i18n.locale]"
        />
        <SelectComponent
          v-model="payment_mode.bank_account_id"
          name="bank_account_id"
          :label="'comptes bancaires'"
          :values="initialsMetadata?.models?.bankAccounts"
          required
          colored
        />
        <hr class="col-span-2 my-5" />
        <SelectFuneralHome
          multiple
          class="col-start-1"
          v-model="payment_mode.funeral_homes"
          edition-mode
          error-key="funeral_homes"
        />
        <div
          v-if="payment_mode.funeral_homes?.length > 1"
          class="col-span-2 col-start-1 gap-2"
        >
          <BadgeItemComponent
            v-for="funeral_home in payment_mode.funeral_homes"
            :key="funeral_home"
            list="funeral_homes"
            :badge-element="funeral_home"
            :bg-color="
              initialsMetadata?.models?.funeralHomes.find(
                (item) => item.id === funeral_home
              )?.bg_color
            "
            :badge-text="
              initialsMetadata?.models?.funeralHomes.find(
                (item) => item.id === funeral_home
              )?.name
            "
            @remove-badge="handleRemoveBadge"
          />
        </div>
        <hr class="col-span-2 my-5" />
        <SelectComponent
          v-model="payment_mode.doc_types"
          name="doc_types"
          class="col-start-1"
          :label="$t('global.document')"
          :values="initialsMetadata?.enums?.invoicing_doc_types"
          required
          colored
          multiple
        />
        <div
          v-if="payment_mode.doc_types?.length > 1"
          class="col-span-2 col-start-1 gap-2"
        >
          <BadgeItemComponent
            v-for="doc_type in payment_mode.doc_types"
            :key="doc_type"
            list="doc_types"
            :badge-element="doc_type"
            :bg-color="
              initialsMetadata?.enums?.invoicing_doc_types.find(
                (item) => item.id === doc_type
              )?.bg_color
            "
            :badge-text="
              initialsMetadata?.enums?.invoicing_doc_types.find(
                (item) => item.id === doc_type
              )?.name
            "
            @remove-badge="handleRemoveBadge"
          />
        </div>
        <hr class="col-span-2 my-5" />
        <SelectComponent
          v-model="payment_mode.doc_roles"
          name="doc_types"
          class="col-start-1"
          :label="'rôles du document'"
          :values="initialsMetadata?.enums?.invoicing_doc_roles"
          required
          colored
          multiple
        />
        <div
          v-if="payment_mode.doc_roles?.length > 1"
          class="col-span-2 col-start-1 gap-2"
        >
          <BadgeItemComponent
            v-for="doc_role in payment_mode.doc_roles"
            :key="doc_role"
            list="doc_roles"
            :badge-element="doc_role"
            :bg-color="
              initialsMetadata?.enums?.invoicing_doc_roles.find(
                (item) => item.id === doc_role
              )?.bg_color
            "
            :badge-text="
              initialsMetadata?.enums?.invoicing_doc_roles.find(
                (item) => item.id === doc_role
              )?.name
            "
            @remove-badge="handleRemoveBadge"
          />
        </div>
        <hr class="col-span-2 my-5" />
        <SelectComponent
          class="col-start-1"
          v-model="payment_mode.payment_modes"
          name="doc_types"
          :label="$t('navigation.content_payment_mode_title')"
          :values="initialsMetadata?.enums?.payment_modes"
          required
          colored
          multiple
        />
        <div
          v-if="payment_mode.payment_modes?.length > 1"
          class="col-span-2 col-start-1 gap-2"
        >
          <BadgeItemComponent
            v-for="paymentMode in payment_mode.payment_modes"
            :key="paymentMode"
            list="payment_modes"
            :badge-element="paymentMode"
            :bg-color="
              initialsMetadata?.enums?.payment_modes.find(
                (item) => item.id === paymentMode
              )?.bg_color
            "
            :badge-text="
              initialsMetadata?.enums?.payment_modes.find(
                (item) => item.id === paymentMode
              )?.name
            "
            @remove-badge="handleRemoveBadge"
          />
        </div>
      </div>
    </PageSection>
  </div>
</template>

<script>
import PageSection from '@c/PageSection.vue'
import { mapGetters } from 'vuex'
import BaseShowEditTranslation from '@c/addf-package/components/BaseShowEditInput/BaseShowEditTranslation.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import SelectFuneralHome from '@c/InitialStateFormComponent/SelectFuneralHome.vue'
import BadgeItemComponent from '@/views/settings/invoicing/PaymentMode/form/tabs/components/BadgeItemComponent.vue'

export default {
  name: 'ThePaymentInformationPage',
  components: {
    BadgeItemComponent,
    SelectFuneralHome,
    SelectComponent,
    BaseShowEditTranslation,
    PageSection
  },
  computed: {
    ...mapGetters({
      editionMode: 'paymentMode/getEditionMode',
      payment_mode: 'paymentMode/getPaymentMode',
      initialsMetadata: 'metadata/getInitialMetadata',
      metadata: 'paymentMode/getPaymentModeMetadata',
      errors: 'auth/getErrors'
    })
  },
  methods: {
    handleRemoveBadge(payload) {
      this.payment_mode[payload.list] = this.payment_mode[payload.list].filter(
        (elt) => elt !== payload.element
      )
    }
  }
}
</script>
