<template>
  <div class="flex h-full w-full flex-col gap-4 p-4">
    <div class="flex shrink-0 flex-row">
      <h2 class="flex-1 text-2xl font-bold leading-6 text-gray-900">
        Release Note
      </h2>
      <div class="shrink-0">
        <BaseButton
          @click="
            $router.push({
              name: 'management_new_release_note_route'
            })
          "
          :title="$t('button.add')"
          :icon="'plus'"
          color="primary"
        />
      </div>
    </div>
    <div class="flex-1">
      <div
        class="flex flex-col divide-y divide-slate-300 rounded-md border border-slate-300"
      >
        <div
          v-for="release in releases"
          :key="release.id"
          class="flex flex-row p-2"
        >
          <div class="flex flex-1 flex-row items-center gap-6">
            <span class="font-semibold">{{ release.version }}</span>
            <span class="text-xs">{{ $h.formatDate(release.date, 'L') }}</span>
          </div>
          <div class="shrink-0">
            <RouterLink
              :to="{
                name: 'management_release_note_list_route',
                params: {
                  releaseId: release.id
                }
              }"
            >
              {{ $t('button.edit') }}
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'TheReleasesPage',
  components: { BaseButton },
  data() {
    return {
      releases: []
    }
  },
  mounted() {
    this.fetchReleaseList()
  },
  methods: {
    ...mapActions({
      processFetchReleaseListAction: 'saManagement/fetchReleaseList'
    }),
    fetchReleaseList() {
      this.processFetchReleaseListAction().then((response) => {
        this.releases = response.releases
      })
    }
  }
}
</script>
