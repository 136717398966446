<template>
  <td
    :class="index === 0 ? 'py-4 pl-4 pr-3' : 'px-3 py-4 text-gray-600'"
    class="flex flex-shrink-0 whitespace-nowrap border-b border-gray-200 text-sm sm:table-cell"
  >
    <slot :column="column" :data="row" :index="index" name="table-cell">
      <span v-if="column?.type === 'icon'" class="cursor-default">
        <FontAwesomeIcon
          v-if="row && row[column?.metadata?.icon]"
          :class="`text-theme-${row[column?.metadata?.color]}`"
          :icon="['fas', row[column?.metadata?.icon]]"
          size="lg"
        />
      </span>
      <span v-if="column?.type === 'link'" class="cursor-pointer underline font-medium text-theme-primary">
        <span :class="`text-theme-${row[column?.metadata?.color]}`" @click="redirectTo">{{ $t('button.link')}}</span>
      </span>
      <span v-else-if="column?.type === 'array'" class="cursor-default">
        <p v-for="(column_field, idx) in row[column?.field]" :key="idx">
          {{ column_field }}
        </p>
      </span>
      <span v-else-if="column?.type === 'label'" class="cursor-default">
        <BaseLabel
          :color="row[column?.metadata?.color]"
          :content="row[column?.metadata?.value]"
          :icon="column.metadata.icon"
        />
      </span>
      <span v-else-if="column?.type === 'date'">
        {{ cValues ? $h.formatDate(cValues, 'DD/MM/YYYY') : '' }}
      </span>
      <span v-else-if="column?.type === 'currency'">
        {{ $h.formatCurrency(cValues) }}
      </span>
      <span v-else-if="column?.type === 'avatar'">
        <AvatarComponent v-if="avatar_user_id" :user_id="avatar_user_id" />
      </span>
      <span
        v-else-if="column?.type === 'logo'"
        class="min-w-8 min-h-8 relative inline-block flex aspect-square h-8 w-8 scale-100"
      >
        <img
          class="rounded-full border-2 border-white shadow-xl ring-1 ring-gray-300"
          alt="logo"
          :src="cValues && cValues.split('.').reduce((o, i) => o[i], row)"
        />
      </span>
      <span v-else class="cursor-default">
        {{ cValues }}
      </span>
    </slot>
  </td>
</template>
<script>
import AvatarComponent from '@c/AvatarComponent'
import BaseLabel from '@c/addf-package/components/BaseLabel/BaseLabel'
import * as _ from 'lodash-es'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'RowElementComponent',
  components: { FontAwesomeIcon, AvatarComponent, BaseLabel },
  props: {
    row: {
      type: Object,
      required: true
    },
    column: {
      type: [Array, Object],
      required: true
    },
    index: {
      type: [String, Number],
      required: true
    },
    actions: {
      type: Array,
      required: true
    },
    orderedRow: {
      type: Array,
      required: true
    },
    actionCol: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    cValues() {
      if (!this.column?.field) {
        return ''
      }
      let values = _.get(this.row, this.column.field)
      return this.column.translatable && values?.[this.$i18n.locale]
        ? values[this.$i18n.locale]
        : values
    }
  },
  methods: {
    redirectTo() {
      if(this.column.model) {
        switch (this.column.model) {
          case 'invoice':
            this.$router.push({
              name: 'invoice_show_route',
              params: { invoicing_id: this.cValues }
            })
            break
      }
    }
    }
  }
}
</script>
