<template>
  <PageSection
    :title="$t('global.contact_details')"
    :disable-sticky-margin="disableStickyMargin"
  >
    <template #default>
      <div v-if="user" class="grid grid-cols-1 gap-x-5 md:grid-cols-2">
        <!--#region first - last name -->
        <div class="order-2 grid grid-cols-1 gap-3 md:order-1">
          <div>
            <SelectCivility
              v-model="user.civility"
              name="civility"
              :required="true"
              :edition-mode="editionMode"
            />
          </div>
          <div>
            <BaseShowEditInput
              v-model="user.first_name"
              :label="$t('attributes.first_name')"
              name="first_name"
              :errors="errors.first_name"
              :placeholder="$t('attributes.first_name')"
              :edition-mode="editionMode"
              :required="true"
            />
          </div>
          <div>
            <BaseShowEditInputNameFormat
              v-model="user.last_name"
              v-model:brut="user.brut_last_name"
              :label="$t('attributes.name')"
              name="name"
              :edition-mode="editionMode"
              :required="true"
              :errors="errors.last_name"
            />
          </div>
          <div>
            <BaseShowEditInput
              v-model="user.initials"
              :label="$t('attributes.initials')"
              name="initials"
              :errors="errors.initials"
              :placeholder="$t('attributes.initials')"
              :required="true"
              :edition-mode="editionMode"
            />
          </div>
        </div>
        <!--#endregion -->
        <!--#region avatar -->
        <div class="order-1 mx-auto md:order-2 md:mr-auto">
          <ImageFileInput
            v-model="user_avatar"
            v-if="user_avatar"
            :edition-mode="editionMode"
            :deletable="true"
            :updatable="true"
            :media="user_avatar"
            :manage-media="true"
            description="user's profile avatar"
            name="user_profile_avatar"
            @file-change="handleAvatarChange"
            :errors="errors.new_avatar_file"
            trigger-label=""
          />
          <ImageFileInput
            v-model="user.initals"
            v-else-if="editionMode && !user_avatar"
            :edition-mode="editionMode"
            description="user's profile avatar"
            name="user_profile_avatar"
            @file-change="handleAvatarChange"
            :errors="errors.new_avatar_file"
          />
          <AvatarComponent
            v-else-if="!editionMode && !user_avatar"
            :size="40"
            :user_id="user.id"
            :display-status="false"
          />
        </div>
        <!--#endregion -->
      </div>
      <div v-if="user" class="grid grid-cols-1 gap-5 md:grid-cols-2">
        <!--#region initials nn, birth_date, tva, -->

        <BaseShowEditInput
          v-model="user.national_number"
          :label="$t('attributes.national_number')"
          name="national_number"
          :errors="errors.national_number"
          :placeholder="$t('attributes.national_number')"
          :edition-mode="editionMode"
        />
        <div>
          <BaseShowEditDatePicker
            v-model:date="user.birth_date"
            :label="$t('attributes.birth_date')"
            :edition-mode="editionMode"
            name="birth_date"
            :errors="errors.birth_date"
            :today-default="false"
          />
        </div>

        <BaseShowEditInput
          v-model="user.TVA"
          :label="$t('attributes.TVA')"
          name="TVA"
          :errors="errors.TVA"
          :placeholder="$t('attributes.TVA')"
          :edition-mode="editionMode"
        />
        <!--#endregion -->
        <!--#region email, language, phone , mobile -->

        <SelectLanguages
          v-model="user.language"
          :edition-mode="editionMode"
          :required="true"
          :errors="errors.language"
          name="language"
          customer-language-only
        />

        <BaseShowEditPhoneInput
          v-model:countryValue="user.mobile_country"
          v-model:numberValue="user.mobile_field"
          class="col-start-1"
          :label="$t('attributes.mobile')"
          :edition-mode="editionMode"
          name="mobile"
          :errors="errors"
          :metadata="metadata"
        />
        <BaseShowEditPhoneInput
          v-model:countryValue="user.phone_country"
          v-model:numberValue="user.phone_field"
          :label="$t('attributes.phone')"
          :edition-mode="editionMode"
          name="phone"
          :errors="errors"
          :metadata="metadata"
          @change.self="getInputValue"
        />
        <!--#endregion -->
        <!--#region Signature_image -->
        <div class="col-start-1">
          <ImageFileInput
            v-if="user.signature_url"
            v-model="user.signature_url"
            :label="$t('attributes.tag')"
            :edition-mode="editionMode"
            :squared="true"
            :triggerIcon="['fal', 'edit']"
            :description="$t('global.signature_tag')"
            name="signature_image"
            @file-change="handleSignatureChange"
            :errors="errors.new_signature_file"
          />
          <ImageFileInput
            v-if="!user.signature_url"
            v-model="user.signature_url"
            :label="$t('attributes.tag')"
            :edition-mode="editionMode"
            :squared="true"
            :triggerIcon="['fal', 'edit']"
            :description="$t('global.signature_tag')"
            name="signature_image"
            @file-change="handleSignatureChange"
            :errors="errors.new_signature_file"
          />
          <!--          <ImageFileInput-->
          <!--            v-model="user.initals"-->
          <!--            v-else-if="editionMode && !user.avatar_url"-->
          <!--            :edition-mode="editionMode"-->
          <!--            description="user's profile avatar"-->
          <!--            name="user_profile_avatar"-->
          <!--            @file-change="handleAvatarChange"-->
          <!--            :errors="errors.new_avatar_file"-->
          <!--          />-->
        </div>
        <!--#endregion -->
      </div>
    </template>
  </PageSection>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import ImageFileInput from '@c/ImageFileInput'
import PageSection from '@c/PageSection'
import SectionTitle from '@c/SectionTitle'
import BaseShowEditDatePicker from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'
import AvatarComponent from '@c/AvatarComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseShowEditPhoneInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditPhoneInput.vue'
import SelectCivilityAndGender from '@c/InitialStateFormComponent/Enums/SelectCivilityAndGender.vue'
import SelectCivility from '@c/InitialStateFormComponent/Enums/SelectCivility.vue'
import BaseShowEditInputNameFormat from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInputNameFormat.vue'

export default {
  name: 'UserPersonalInformation',
  components: {
    BaseShowEditInputNameFormat,
    SelectCivility,
    SelectCivilityAndGender,
    BaseShowEditPhoneInput,
    BaseShowEditInput,
    FontAwesomeIcon,
    AvatarComponent,
    SelectLanguages,
    BaseShowEditDatePicker,
    ImageFileInput,
    PageSection,
    SectionTitle
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    metadata: {
      type: [Object, Array],
      required: true
    },
    editionMode: {
      type: Boolean,
      required: true
    },
    disableStickyMargin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors'
    })
  },
  data() {
    return {
      user_avatar: null,
      is_avatar_updated: false,
      is_signature_updated: false
    }
  },
  watch: {
    editionMode(newValue, oldValue) {
      console.log('watch', newValue)
      if (newValue !== oldValue && !newValue) {
        this.processFetchUserByIDAction({ id: this.user.id })
        console.log(this.user.avatar_url)
        this.user_avatar = this.user.avatar_url
      }
    }
  },
  methods: {
    ...mapMutations({ processSetUserMutation: 'user/setUser' }),
    ...mapActions({
      processFetchUserByIDAction: 'user/fetchUserByID',
      processAddUserAvatarAction: 'user/addUserAvatar',
      processDeleteUserAvatarAction: 'user/deleteUserAvatar'
    }),
    getInputValue({ attribute, value }) {
      this.user[attribute] = value
    },
    handleAvatarChange(file) {
      this.user.is_avatar_updated = true
      this.user.new_avatar_file = file
      this.loading = true
      if (file !== null) {
        this.processAddUserAvatarAction({
          user_id: this.user.id,
          new_avatar_file: this.user.new_avatar_file
        }).then(async (response) => {
          // this.processSetUserMutation(response.user)
          console.log('add', response.user_avatar)
          this.user_avatar = response.user_avatar
          this.$h.toastSuccess(
            this.$t('toasts.created_m', { var: this.$t('attributes.avatar') })
          )
        })
      } else {
        this.processDeleteUserAvatarAction({
          user_id: this.user.id
        }).then(async (response) => {
          // this.processSetUserMutation(response.user)
          console.log('delete', response.user_avatar)
          this.user_avatar = response.user_avatar
          this.$h.toastSuccess(
            this.$t('toasts.deleted_v', { var: this.$t('attributes.avatar') })
          )
        })
      }
    },
    handleSignatureChange(file) {
      this.user.is_signature_updated = true
      this.user.new_signature_file = file
      this.processSetUserMutation(this.user)
    }
  },
  mounted() {
    console.log('mounted', this.user.avatar_url)
    this.user_avatar = this.user.avatar_url
  }
}
</script>
