<template>
  <div>
    <div class="fm-form-input mt-2">
      <label> SMTP </label>
      <div
        class="flex flex-row items-center overflow-hidden rounded-lg bg-gray-200 dark:bg-slate-500"
      >
        <input
          :value="labelText"
          readonly
          class="focus:outline-0"
          @click="editionMode ? (isModalOpen = true) : undefined"
        />
        <button
          type="button"
          class="flex h-8 w-10 flex-shrink-0 items-center justify-center"
          @click="editionMode ? (isModalOpen = true) : undefined"
        >
          <FontAwesomeIcon :icon="['fal', 'edit']" />
        </button>
      </div>
    </div>
  </div>
  <SMTPConfiguratorModal
    v-model:open="isModalOpen"
    v-model:settings="localSettings"
  />
</template>

<script>
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SMTPConfiguratorModal from '@c/Notification/SMTPConfigurator/SMTPConfiguratorModal.vue'

export default {
  name: 'SMTPConfigurator',
  components: {
    SMTPConfiguratorModal,
    FontAwesomeIcon,
    ErrorContainer,
    BaseShowEditInput
  },
  props: {
    settings: {
      type: Object,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:settings'],
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    localSettings: {
      get() {
        return this.settings
      },
      set(value) {
        this.$emit('update:settings', value)
      }
    },
    labelText() {
      return this.settings?.custom ? 'custom' : 'Funeral Manager'
    }
  }
}
</script>
