<template>
  <BaseSlideOverModal v-model:is-modal-open="localOpen" title="SMTP">
    <template #body>
      <div class="w-full">
        <div class="grid-form">
          <div class="col-span-2">
            <WalkmanButton
              label="Custom"
              :options="[
                { value: 1, label: this.$t(`enums.yesNo.true`) },
                { value: 0, label: this.$t(`enums.yesNo.false`) }
              ]"
              v-model="localSettings.custom"
            />
          </div>
          <div class="col-span-2">
            <BaseShowEditInput v-model="localSettings.host" label="Host" />
          </div>
          <div class="col-span-2">
            <BaseShowEditInput v-model="localSettings.port" label="Port" />
          </div>
          <div class="col-span-2">
            <BaseShowEditInput
              v-model="localSettings.username"
              label="Username"
            />
          </div>
          <div class="col-span-2">
            <BaseShowEditInput
              v-model="localSettings.password"
              label="Password"
            />
          </div>
          <div class="col-span-2">
            <WalkmanButton
              label="Encryption"
              :options="[
                { value: 'no', label: 'no' },
                { value: 'ssl', label: 'ssl' },
                { value: 'tls', label: 'tls' }
              ]"
              v-model="localSettings.encryption"
            />
          </div>
        </div>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseShowEditSwitch from '@c/BaseShowEditSwitch.vue'
import BaseShowEditUndefinedRadioGroup from '@c/addf-package/components/BaseRadioGroup/BaseShowEditUndefinedRadioGroup.vue'
import WalkmanButton from '@c/WalkmanButton.vue'

export default {
  name: 'SMTPConfiguratorModal',
  components: {
    WalkmanButton,
    BaseShowEditUndefinedRadioGroup,
    BaseShowEditSwitch,
    BaseShowEditInput,
    BaseSlideOverModal
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  emits: ['update:open', 'update:settings'],
  computed: {
    localSettings: {
      get() {
        return this.settings
      },
      set(value) {
        this.$emit('update:settings', value)
      }
    },
    localOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  }
}
</script>
