<template>
  <div v-if="showAlert" class="p-3" style="max-width: 250px">
    <div
      class="flex flex-col gap-1 rounded-lg border border-primary-600 bg-gradient-to-br from-secondary-400 to-secondary-500 p-2 text-xs text-contrast-secondary"
    >
      <div class="truncate font-bold">
        {{ $t('alert.funeral_manager_has_been_updated') }}
      </div>
      <div>
        <RouterLink
          :to="{
            name: 'settings_version_note_index_route'
          }"
          class="flex flex-row items-center gap-2"
        >
          <div>
            <FontAwesomeIcon :icon="['fas', 'star']" size="xs" />
          </div>
          <div>{{ $t('alert.see_whats_new') }}</div>
          <div>
            <FontAwesomeIcon :icon="['fal', 'arrow-right']" />
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'

export default {
  name: 'ReleaseNotificationComponent',
  components: { FontAwesomeIcon },
  computed: {
    ...mapGetters({
      auth: 'auth/getUser',
      release_version: 'metadata/getReleaseVersion'
    }),
    showAlert() {
      return (
        this.release_version &&
        (!this.auth.settings?.release_notes?.last_seen_version ||
          this.auth.settings?.release_notes?.last_seen_version !==
            this.release_version)
      )
    }
  }
}
</script>
