<template>
  <PageSection :title="$t('global.email')">
    <div v-if="user" class="grid-form">
      <div>
        <BaseShowEditInput
          v-model="user.email"
          :label="$t('attributes.email')"
          name="email"
          :errors="errors.email"
          :placeholder="$t('attributes.email')"
          :edition-mode="editionMode"
          :required="true"
        />
      </div>
      <div v-if="user?.settings?.smtp">
        <SMTPConfigurator
          v-model:settings="user.settings.smtp"
          :edition-mode="editionMode"
        />
      </div>
      <div class="col-span-2">
        <BaseShowEditVariableWysiwyg
          v-model="user.email_signature"
          :label="$t('attributes.email_signature')"
          :metadata="metadata"
          name="email_signature"
          :edition-mode="editionMode"
          :errors="errors.email_signature"
        />
      </div>
      <div class="col-span-2">
        <BaseShowEditSwitch
          :key="isDefaultSenderEmailAddressUser"
          v-model="isDefaultSenderEmailAddressUser"
          :label="$t('attributes.personal_email')"
          :edition-mode="editionMode"
          name="personal_email"
          :errors="errors.personal_email"
          @change="setPersonalEmail"
        />
      </div>
      <div class="col-span-2">
        <BaseShowEditSwitch
          :key="isDefaultSenderEmailAddressDC"
          v-model="isDefaultSenderEmailAddressDC"
          :label="$t('attributes.company_email')"
          :edition-mode="editionMode"
          name="company_email"
          :errors="errors.company_email"
          @change="setCompanyEmail"
        />
      </div>
    </div>
  </PageSection>
</template>
<script>
import BaseShowEditSwitch from '@c/BaseShowEditSwitch'
import SectionTitle from '@c/SectionTitle'
import { mapGetters } from 'vuex'
import PageSection from '@c/PageSection'
import BaseShowEditVariableWysiwyg from '@c/BaseShowEditVariableWysiwyg'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import SMTPConfigurator from '@c/Notification/SMTPConfigurator/SMTPConfigurator.vue'

export default {
  name: 'UserEmail',
  components: {
    SMTPConfigurator,
    BaseShowEditInput,
    PageSection,
    BaseShowEditVariableWysiwyg,
    BaseShowEditSwitch,
    SectionTitle
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    metadata: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors'
    }),
    isDefaultSenderEmailAddressUser() {
      return this.user?.default_sender_email_adress === 'USER'
    },
    isDefaultSenderEmailAddressDC() {
      return this.user?.default_sender_email_adress === 'DC'
    }
  },
  methods: {
    setPersonalEmail(payload) {
      payload === true
        ? (this.user.default_sender_email_adress = 'USER')
        : (this.user.default_sender_email_adress = 'DC')
    },
    setCompanyEmail(payload) {
      payload === true
        ? (this.user.default_sender_email_adress = 'DC')
        : (this.user.default_sender_email_adress = 'USER')
    }
  }
}
</script>
